import React, { useState, useEffect } from 'react';
import { SpaceBetween, Cards } from '@amzn/awsui-components-react/polaris';
import CandidateInfo from './components/CandidateInfo';
import DocInfo from './components/SessionInfo';
import SessionState from './components/SessionState';
import VotingSection from './components/VotingSection';
import WarningModal from './components/WarningModal';
import { usePapiProfile } from '@/api/amzn-people';
import { DocumentReviewSessionVoteItem, DocumentReviewSessionResource } from '@/models';
import { DocumentReviewSessionState, DocumentReviewSessionVote } from '@/api/API';
import { useAppContext } from '@/contexts';
import { useDocumentReviewSesionVoteRecords, useDocumentReviewSessionActions } from '@/api/document-review';
import { REVIEW_SESSION_REFRESH_SECONDS } from './config';
import { MIN_REVIEWERS_PER_DOCUMENT_REVIEW } from '@/common/constants';
import { DocumentReviewSessionsPage } from '@/common/pages';
import useNavigator from '@/common/hooks/use-navigator';

const ViewDocumentReviewSession = ({ documentReviewSession }) => {
  const [localReviewSessionData, setLocalReviewSessionData] =
    useState<DocumentReviewSessionResource>(documentReviewSession);
  const [initialSessionState, setInitialSessionState] = useState<DocumentReviewSessionState>(
    localReviewSessionData.sessionState
  );
  const [userVote, setUserVote] = useState({ documentReady: null, candidateReady: null });
  const [documentReadyVote, setDocumentReadyVote] = useState<DocumentReviewSessionVote>();
  const [candidateReadyVote, setCandidateReadyVote] = useState<DocumentReviewSessionVote>();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [overrideReason, setOverrideReason] = useState('');
  const [overrideReasonValidationText, setOverrideReasonValidationText] = useState<string>('');
  const [reviewSessionState, setReviewSessionState] = useState(localReviewSessionData.sessionState);
  const { reviewSessionActions } = useDocumentReviewSessionActions(localReviewSessionData.id);
  const { listVotesBySessionId, onCreate, onUpdate } = useDocumentReviewSesionVoteRecords();
  const [sessionVotes, setSessionVotes] = useState<Map<string, DocumentReviewSessionVoteItem>>(new Map());
  const { user: candidateProfile } = usePapiProfile(localReviewSessionData.candidateAlias);
  const { currentUser, spoofUser } = useAppContext();
  const { goToPage } = useNavigator();
  const user = spoofUser?.alias || currentUser?.alias;

  const isOwner = user === localReviewSessionData.ownerAlias;

  useEffect(() => {
    void listVotesBySessionId({ documentReviewSession: localReviewSessionData.id, alias: user }).then((results) => {
      setSessionVotes(new Map(results?.map((vote) => [vote.alias, vote])));
    });

    const pollInterval = setInterval(() => {
      void listVotesBySessionId({ documentReviewSession: localReviewSessionData.id, alias: user }).then((results) => {
        setSessionVotes(new Map(results?.map((vote) => [vote.alias, vote])));
      });
    }, REVIEW_SESSION_REFRESH_SECONDS * 10000);

    return () => clearInterval(pollInterval);
  }, [listVotesBySessionId, localReviewSessionData, user]);

  const handleSaveStateChange = async (newState) => {
    if (localReviewSessionData.sessionState !== newState) {
      let runUpdate = true;
      if (newState === DocumentReviewSessionState.COMPLETE) {
        let yesYesCount = 0;
        sessionVotes.forEach((vote) => {
          if (
            vote.documentReady &&
            vote.documentReady === 'YES' &&
            vote.candidateReady &&
            vote.candidateReady === 'YES'
          ) {
            yesYesCount += 1;
          }
        });
        if (yesYesCount === 0) {
          runUpdate = false;
          setShowWarningModal(true);
          return;
        }
      }

      if (runUpdate) {
        const updateResult = await reviewSessionActions.update({
          sessionState: newState,
          overrideReason,
        });
        if (updateResult && updateResult.sessionState === newState) {
          if (newState === DocumentReviewSessionState.COMPLETE) goToPage(DocumentReviewSessionsPage);
          setReviewSessionState(updateResult.sessionState);
          setInitialSessionState(updateResult.sessionState);
          setLocalReviewSessionData(updateResult);
        }
      }
    }
  };

  const handleModalOverride = async () => {
    if (overrideReason) {
      setShowWarningModal(false);
      const updateResult = await reviewSessionActions.update({
        sessionState: DocumentReviewSessionState.COMPLETE,
        overrideReason,
      });
      if (updateResult && updateResult.sessionState === DocumentReviewSessionState.COMPLETE) {
        setReviewSessionState(updateResult.sessionState);
        setInitialSessionState(updateResult.sessionState);
        setLocalReviewSessionData(updateResult);
        goToPage(DocumentReviewSessionsPage);
      }
    }
  };

  const handleModalDismiss = () => {
    setShowWarningModal(false);
    setReviewSessionState(localReviewSessionData.sessionState);
  };
  const handleStateChange = (newState) => {
    setReviewSessionState(newState);
  };

  const handleVoteRefresh = () => {
    void listVotesBySessionId({ documentReviewSession: localReviewSessionData.id, alias: user }).then((results) => {
      setSessionVotes(new Map(results?.map((vote) => [vote.alias, vote])));
    });
  };

  const handleVote = (voteType, value) => {
    if (voteType) {
      if (voteType === 'documentReady') {
        if (value === 'Yes') {
          setDocumentReadyVote(DocumentReviewSessionVote.YES);
        }
        if (value === 'No') {
          setDocumentReadyVote(DocumentReviewSessionVote.NO);
        }
      }
      if (voteType === 'candidateReady') {
        if (value === 'Yes') {
          setCandidateReadyVote(DocumentReviewSessionVote.YES);
        }
        if (value === 'No') {
          setCandidateReadyVote(DocumentReviewSessionVote.NO);
        }
      }
    }
    setUserVote((prevVote) => ({ ...prevVote, [voteType]: value }));
  };

  const handleVoteSave = () => {
    if (candidateReadyVote && documentReadyVote) {
      if (sessionVotes.has(user!) && sessionVotes.get(user!)?.id) {
        // console.log('Vote exists, updating for ', user);
        const voteId = sessionVotes.get(user!)?.id;
        void onUpdate({
          id: voteId!,
          documentReviewSession: localReviewSessionData.id,
          reviewerAlias: user!,
          documentReady: documentReadyVote,
          candidateReady: candidateReadyVote,
        });
      } else if (
        localReviewSessionData.documentReviewers.map((reviewer) => {
          return reviewer.alias === user;
        })
      ) {
        // console.log('Vote does NOT exist, creating for ', user);
        void onCreate({
          documentReviewSession: localReviewSessionData.id,
          reviewerAlias: user!,
          documentReady: documentReadyVote,
          candidateReady: candidateReadyVote,
        });
      }
    }
  };

  const items = [
    {
      header: 'Candidate Information',
      content: <CandidateInfo candidateProfile={candidateProfile} />,
    },
    {
      header: 'Session Information',
      content: <DocInfo documentReviewSession={localReviewSessionData} />,
    },
    {
      header: 'Session State',
      content: (
        <SessionState
          isOwner={isOwner}
          initialSessionState={initialSessionState}
          sessionState={reviewSessionState}
          onStateChange={handleStateChange}
          onSaveStateChange={handleSaveStateChange}
        />
      ),
    },
    {
      header: isOwner ? 'Reviewer Votes' : 'Your Vote',
      content: (
        <VotingSection
          isOwner={isOwner}
          sessionData={localReviewSessionData}
          sessionVotes={sessionVotes}
          userVote={userVote}
          onVote={handleVote}
          onSave={handleVoteSave}
          onRefresh={handleVoteRefresh}
        />
      ),
    },
  ];

  return (
    <>
      <WarningModal
        visible={showWarningModal}
        minReviewers={MIN_REVIEWERS_PER_DOCUMENT_REVIEW}
        overrideReason={overrideReason}
        setOverrideReason={setOverrideReason}
        overrideReasonValidationText={overrideReasonValidationText}
        setOverrideReasonValidationText={setOverrideReasonValidationText}
        onDismiss={handleModalDismiss}
        onProceed={handleModalOverride}
      />
      <SpaceBetween direction="vertical" size="l">
        <Cards
          items={items}
          cardDefinition={{
            header: (item) => item.header,
            sections: [
              {
                id: 'content',
                content: (item) => item.content,
              },
            ],
          }}
          cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
        />
      </SpaceBetween>
    </>
  );
};

export default ViewDocumentReviewSession;
