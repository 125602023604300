/* eslint-disable no-underscore-dangle */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import {
  CalibratedReviewerItem,
  Nullable,
  OptionalString,
  PanelReviewSessionCommentItem,
  PanelReviewSessionItem,
  PanelReviewSessionResource,
  PanelReviewSessionVoteItem,
  ReviewSessionBase,
} from '@/models';
import { QueryKeys } from './queryKeys';
import { getConcreteModels, useApiMutation, useApiQuery } from '@/backend/api';

import {
  PanelReviewSessionActions,
  UseCalibratedPanelReviewersResult,
  UsePanelReviewActionsResult,
  UsePanelReviewCommentsResult,
  UsePanelReviewersResult,
  UsePanelReviewSessionResult,
  UsePanelReviewSessionsResult,
  UsePanelReviewVotesResult,
} from './models';
import {
  GetCalibratedPanelReviewerRecordByAliasQuery,
  GetCalibratedPanelReviewerRecordByAliasQueryVariables,
  ListCalibratedPanelReviewerRecordsQuery,
  ListCalibratedPanelReviewerRecordsQueryVariables,
  ListPanelReviewSessionsByOwnerQuery,
  ListPanelReviewSessionsByOwnerQueryVariables,
  ModelStringKeyConditionInput,
  PanelReviewState,
  PanelReviewRecord as PanelReviewSessionModel,
  GetPanelReviewRecordQuery,
  GetPanelReviewRecordQueryVariables,
  CreatePanelReviewSessionToReviewerMappingInput,
  CreatePanelReviewSessionToReviewerMappingMutation,
  DeletePanelReviewSessionToReviewerMappingInput,
  DeletePanelReviewSessionToReviewerMappingMutation,
  GetPanelReviewSessionToReviewerMappingQuery,
  GetPanelReviewSessionToReviewerMappingQueryVariables,
  CreatePanelReviewRecordInput,
  CreatePanelReviewRecordMutation,
  DeletePanelReviewRecordInput,
  DeletePanelReviewRecordMutation,
  PanelReviewSessionToReviewerMapping,
  UpdatePanelReviewRecordInput,
  UpdatePanelReviewRecordMutation,
  ListVotesbyPanelReviewQueryVariables,
  ListVotesbyPanelReviewQuery,
  CreatePanelReviewVoteRecordInput,
  CreatePanelReviewVoteRecordMutation,
  GetPanelReviewVoteRecordQuery,
  GetPanelReviewVoteRecordQueryVariables,
  UpdatePanelReviewVoteRecordInput,
  UpdatePanelReviewVoteRecordMutation,
  PanelReviewVoteRecord,
  ListVotesbyPanelReviewerQuery,
  ListVotesbyPanelReviewerQueryVariables,
  ListVotesbyPanelReviewStateQueryVariables,
  ListVotesbyPanelReviewStateQuery,
  ListCommentsByPanelReviewQueryVariables,
  ListCommentsByPanelReviewQuery,
  PanelReviewComment,
  CreatePanelReviewCommentMutation,
  CreatePanelReviewCommentInput,
  GetPanelReviewCommentQuery,
  GetPanelReviewCommentQueryVariables,
  UpdatePanelReviewCommentInput,
  UpdatePanelReviewCommentMutation,
  DeletePanelReviewCommentInput,
  DeletePanelReviewCommentMutation,
  ListPanelReviewSessionsByAreaLeadAliasQueryVariables,
  ListPanelReviewSessionsByAreaLeadAliasQuery,
} from './API';
import {
  getCalibratedPanelReviewerRecordByAlias,
  getPanelReviewComment,
  getPanelReviewRecord,
  getPanelReviewSessionToReviewerMapping,
  getPanelReviewVoteRecord,
  listCalibratedPanelReviewerRecords,
  listCommentsByPanelReview,
  listPanelReviewSessionsByAreaLeadAlias,
  listPanelReviewSessionsByOwner,
  listVotesbyPanelReview,
  listVotesbyPanelReviewer,
  listVotesbyPanelReviewState,
} from '@/graphql/queries';
import { getCompactId, getDateFromAPIValueUTC } from '@/common/utils';
import {
  createPanelReviewComment,
  createPanelReviewRecord,
  createPanelReviewSessionToReviewerMapping,
  createPanelReviewVoteRecord,
  deletePanelReviewComment,
  deletePanelReviewRecord,
  deletePanelReviewSessionToReviewerMapping,
  updatePanelReviewComment,
  updatePanelReviewRecord,
  updatePanelReviewVoteRecord,
} from '@/graphql/mutations';
import { useNotifications } from '@/contexts';

type ResourceModel =
  | PanelReviewSessionModel
  | NonNullable<CreatePanelReviewRecordMutation['createPanelReviewRecord']>
  | NonNullable<UpdatePanelReviewRecordMutation['updatePanelReviewRecord']>;

type Model =
  | PanelReviewSessionModel
  | NonNullable<CreatePanelReviewRecordMutation['createPanelReviewRecord']>
  | NonNullable<UpdatePanelReviewRecordMutation['updatePanelReviewRecord']>
  | NonNullable<NonNullable<ListPanelReviewSessionsByOwnerQuery['listPanelReviewSessionsByOwner']>['items'][number]>;

type ReviewerModel = NonNullable<
  NonNullable<GetCalibratedPanelReviewerRecordByAliasQuery['getCalibratedPanelReviewerRecordByAlias']>['items'][number]
>;

type ItemModel = NonNullable<
  NonNullable<ListPanelReviewSessionsByOwnerQuery['listPanelReviewSessionsByOwner']>['items'][number]
>;

type ReviewerItemModel = NonNullable<
  NonNullable<GetCalibratedPanelReviewerRecordByAliasQuery['getCalibratedPanelReviewerRecordByAlias']>['items'][number]
>;

type SessionVoteItemModel =
  | PanelReviewVoteRecord
  | NonNullable<NonNullable<ListVotesbyPanelReviewQuery['listVotesbyPanelReview']>['items'][number]>;

type SessionCommentItemModel =
  | PanelReviewComment
  | NonNullable<NonNullable<ListCommentsByPanelReviewQuery['listCommentsByPanelReview']>['items'][number]>;

function isValidItem(model: Nullable<Model>): boolean {
  return !!model;
}

function isValidReviewerItem(model: Nullable<ReviewerModel>): boolean {
  return !!model;
}

function isValidSessionVoteItem(model: Nullable<SessionVoteItemModel>): boolean {
  return !!model;
}

function isValidSessionCommentItem(model: Nullable<SessionCommentItemModel>): boolean {
  return !!model;
}

function isValidResource(model: Nullable<ResourceModel>): boolean {
  return isValidItem(model) && model?.sessionState !== PanelReviewState.CANCELLED;
}

function getReviewerItemFromModel(model: ReviewerModel): CalibratedReviewerItem {
  return {
    id: model.id,
    alias: model.alias,
    completedSessions: model.completedSessions || 0,
  };
}

function getBaseFromModel(model: Model): ReviewSessionBase {
  return {
    id: model.id,
    candidateAlias: model.candidateAlias,
    ownerAlias: model.ownerAlias,
    createdAt: getDateFromAPIValueUTC(model.createdAt),
    updatedAt: getDateFromAPIValueUTC(model.updatedAt),
    workdocsLink: model.workdocsLink,
    sessionStart: model.sessionStart,
    version: model._version,
  };
}

function getItemFromModel(model: Model): PanelReviewSessionItem {
  return {
    ...getBaseFromModel(model),
    chimeLink: model.chimeLink,
    sessionState: model.sessionState,
  };
}

function getResourceFromModel(model: ResourceModel): PanelReviewSessionResource {
  return {
    ...getBaseFromModel(model),
    chimeLink: model.chimeLink,
    sessionState: model.sessionState,
    areaLeadAlias: model.areaLeadAlias,
    documentReviewSession: model.documentReviewSession,
    panelReviewers:
      model.panelReviewers?.items.map((item) => ({
        id: item?.id,
        alias: item?.calibratedPanelReviewerRecord?.alias,
        completedSessions: item?.calibratedPanelReviewerRecord?.completedSessions,
      })) ?? [],
  };
}

function getSessionVoteItemFromModel(model: SessionVoteItemModel): PanelReviewSessionVoteItem {
  return {
    id: model.id,
    panelReviewSessionId: model.panelReview,
    alias: model.reviewerAlias,
    vote: model.vote,
    voteStage: model.voteStage,
    version: model._version,
  };
}

function getSessionCommentItemFromModel(model: SessionCommentItemModel): PanelReviewSessionCommentItem {
  return {
    id: model.id,
    panelReviewSessionId: model.panelReviewId!,
    alias: model.alias,
    voteStage: model.voteStage,
    content: model.content,
    createdAt: getDateFromAPIValueUTC(model.createdAt),
    updatedAt: getDateFromAPIValueUTC(model.updatedAt),
    version: model._version,
  };
}

// eslint-disable-next-line import/prefer-default-export
export function useCalibratedPanelReviewerRecords(): UseCalibratedPanelReviewersResult {
  const { getItems } = useApiQuery();

  const query = useQuery({
    meta: { errorMessage: `Error fetching calibrated panel reviewers` },
    queryKey: QueryKeys.calibratedPanelReviewer.all,
    queryFn: async () => {
      const calibratedPanelReviewerRecords = await getItems<
        ListCalibratedPanelReviewerRecordsQueryVariables,
        ListCalibratedPanelReviewerRecordsQuery
      >({
        query: listCalibratedPanelReviewerRecords,
        input: {},
      });

      const calibratedPanelReviewerModels = getConcreteModels<ReviewerItemModel>(
        calibratedPanelReviewerRecords?.listCalibratedPanelReviewerRecords?.items,
        isValidReviewerItem
      );

      return calibratedPanelReviewerModels?.map((item) => getReviewerItemFromModel(item)) ?? [];
    },
  });

  return {
    calibratedPanelReviewers: query.data ?? [],
    isCalibratedPanelReviewersLoading: query.isPending,
  };
}

export function usePanelReviewSessions(
  alias: OptionalString,
  states?: PanelReviewState[]
): UsePanelReviewSessionsResult {
  const { getItems } = useApiQuery();

  const query = useQuery({
    meta: { errorMessage: `Error fetching panel review sessions for: ${getCompactId(alias)}@` },
    queryKey: QueryKeys.panelReviewSession.alias(alias).status(states),
    queryFn: async () => {
      if (!alias) return undefined;
      let reviewState: ModelStringKeyConditionInput | undefined;
      if (states?.length === 1) {
        reviewState = { eq: states[0] };
      }
      if (states?.length && states.length > 1) {
        reviewState = { between: [states.sort()[0], states.sort()[1]] };
      }

      const reviewSessionsByOwner = await getItems<
        ListPanelReviewSessionsByOwnerQueryVariables,
        ListPanelReviewSessionsByOwnerQuery
      >({
        query: listPanelReviewSessionsByOwner,
        input: { ownerAlias: alias, sessionState: reviewState },
      });

      const ownerSessionModels = getConcreteModels<ItemModel>(
        reviewSessionsByOwner?.listPanelReviewSessionsByOwner?.items,
        isValidItem
      );

      const reviewer = await getItems<
        GetCalibratedPanelReviewerRecordByAliasQueryVariables,
        GetCalibratedPanelReviewerRecordByAliasQuery
      >({
        query: getCalibratedPanelReviewerRecordByAlias,
        input: { alias, filter: { _deleted: { ne: true } } },
      });

      const reviewerModel = getConcreteModels<ReviewerItemModel>(
        reviewer?.getCalibratedPanelReviewerRecordByAlias?.items[0],
        isValidReviewerItem
      );

      const reviewSessionsBySkip = await getItems<
        ListPanelReviewSessionsByAreaLeadAliasQueryVariables,
        ListPanelReviewSessionsByAreaLeadAliasQuery
      >({
        query: listPanelReviewSessionsByAreaLeadAlias,
        input: { areaLeadAlias: alias },
      });

      const skipSessionModels = getConcreteModels<ItemModel>(
        reviewSessionsBySkip?.listPanelReviewSessionsByAreaLeadAlias?.items,
        isValidItem
      );

      let reviewerSessionModels;
      if (reviewerModel?.length === 1) {
        reviewerSessionModels =
          reviewerModel[0].panelReviews?.items
            .filter((item) => item?.panelReviewRecord.sessionState !== PanelReviewState.CANCELLED)
            .map((item) => item?.panelReviewRecord) ?? [];
      }
      const allSessionModels = [
        ...(ownerSessionModels?.map((item) => getItemFromModel(item)) ?? []),
        ...(reviewerSessionModels ?? []),
        ...(skipSessionModels?.map((item) => getItemFromModel(item)) ?? []),
      ];

      return allSessionModels;
    },
    enabled: !!alias,
  });

  return {
    panelReviewSessions: query.data ?? [],
    isPanelReviewSessionsLoading: !!alias && query.isPending,
  };
}

export function usePanelReviewSession(id: OptionalString): UsePanelReviewSessionResult {
  const { getItem } = useApiQuery();
  const queryClient = useQueryClient();

  const getQueryParams = useCallback(
    (panelReviewSessionId: OptionalString) => ({
      meta: { errorMessage: `Error fetching panel review session: ${getCompactId(panelReviewSessionId)}` },
      queryKey: QueryKeys.panelReviewSession.id(panelReviewSessionId),
      queryFn: async () => {
        if (!panelReviewSessionId) return null;
        const data = await getItem<GetPanelReviewRecordQueryVariables, GetPanelReviewRecordQuery>({
          query: getPanelReviewRecord,
          input: { id: panelReviewSessionId },
        });
        const models = getConcreteModels<ResourceModel>(data?.getPanelReviewRecord, isValidResource);
        return models?.length ? getResourceFromModel(models[0]) : null;
      },
    }),
    [getItem]
  );

  const query = useQuery({
    ...getQueryParams(id),
    enabled: !!id,
  });

  const getPanelReviewSession = useCallback(
    async (panelReviewSessionId: OptionalString) => queryClient.fetchQuery({ ...getQueryParams(panelReviewSessionId) }),
    [queryClient, getQueryParams]
  );

  return {
    getPanelReviewSession,
    panelReviewSession: query.data,
    isPanelReviewSessionLoading: !!id && query.isPending,
  };
}

export function usePanelReviewSessionActions(id?: OptionalString): UsePanelReviewActionsResult {
  const { createItem, updateItem, deleteItem } = useApiMutation();
  const { getPanelReviewSession } = usePanelReviewSession(id);
  const { addNotification } = useNotifications();
  const queryClient = useQueryClient();
  const { detachReviewer } = usePanelReviewerActions();

  type CreateParams = Parameters<PanelReviewSessionActions['create']>[0];
  type UpdateParams = Parameters<PanelReviewSessionActions['update']>[0];
  type DeleteParams = Parameters<PanelReviewSessionActions['delete']>[0];

  const invalidateQueries = useCallback(async () => {
    const invalidations = [queryClient.invalidateQueries({ queryKey: QueryKeys.panelReviewSession.all })];
    await Promise.allSettled(invalidations);
  }, [queryClient]);

  const createMutation = useMutation({
    mutationFn: async (params: CreateParams) => {
      const input: CreatePanelReviewRecordInput = { ...params };
      const data = await createItem<CreatePanelReviewRecordMutation>(createPanelReviewRecord, input);
      return data?.createPanelReviewRecord
        ? getResourceFromModel(data?.createPanelReviewRecord as PanelReviewSessionModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully created panel review session [${getCompactId(result?.id)}] for candidate [${result?.candidateAlias}]`,
      });
      invalidateQueries();
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateParams) => {
      const panelReview = await getPanelReviewSession(id);
      if (!panelReview) return null;
      const input: UpdatePanelReviewRecordInput = {
        ...params,
        id: panelReview.id,
        _version: panelReview.version,
      };
      const data = await updateItem<UpdatePanelReviewRecordMutation>(updatePanelReviewRecord, input);
      return data?.updatePanelReviewRecord
        ? getResourceFromModel(data?.updatePanelReviewRecord as PanelReviewSessionModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully updated panel review ${getCompactId(result?.id)} for candidate [${result?.candidateAlias}]`,
      });
      invalidateQueries();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (panelReviewId: DeleteParams) => {
      const panelReview = await getPanelReviewSession(panelReviewId ?? id);
      if (!panelReview || panelReview.sessionState !== PanelReviewState.SCHEDULED) return null;

      const updateInput: UpdatePanelReviewRecordInput = {
        id: panelReview.id,
        sessionState: PanelReviewState.CANCELLED,
        _version: panelReview.version,
      };

      const updateResult = await updateItem<UpdatePanelReviewRecordMutation>(updatePanelReviewRecord, updateInput);

      let deleteResult: Nullable<DeletePanelReviewRecordMutation>;

      if (updateResult?.updatePanelReviewRecord) {
        if (updateResult.updatePanelReviewRecord.panelReviewers?.items?.length) {
          const reviewerMappings = updateResult.updatePanelReviewRecord.panelReviewers.items.filter(
            (mapping): mapping is PanelReviewSessionToReviewerMapping => !!mapping
          );
          await Promise.allSettled(
            reviewerMappings.map((mapping) => detachReviewer({ id: mapping.id, _version: mapping._version }))
          );
        }

        const input: DeletePanelReviewRecordInput = {
          id: updateResult.updatePanelReviewRecord.id,
          _version: updateResult.updatePanelReviewRecord._version,
        };
        deleteResult = await deleteItem<DeletePanelReviewRecordMutation>(deletePanelReviewRecord, input);
      }

      return deleteResult?.deletePanelReviewRecord ?? null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully deleted panel review [${getCompactId(result?.id)}] for candidate [${result?.candidateAlias}`,
      });
      invalidateQueries();
    },
  });

  const onCreate = useCallback(async (params: CreateParams) => createMutation.mutateAsync(params), [createMutation]);
  const onUpdate = useCallback(async (params: UpdateParams) => updateMutation.mutateAsync(params), [updateMutation]);
  const onDelete = useCallback(
    async (panelReviewId: DeleteParams) => deleteMutation.mutateAsync(panelReviewId),
    [deleteMutation]
  );

  const reviewSessionActions = useMemo(
    () => ({ create: onCreate, update: onUpdate, delete: onDelete }),
    [onCreate, onUpdate, onDelete]
  );

  return {
    reviewSessionActions,
    isMutating: createMutation.isPending || updateMutation.isPending || deleteMutation.isPending,
  };
}

export function usePanelReviewerActions(): UsePanelReviewersResult {
  const { createItem, deleteItem } = useApiMutation();
  const { getItem } = useApiQuery();
  const queryClient = useQueryClient();

  type AttachByIdParams = Parameters<UsePanelReviewersResult['attachReviewerById']>[0];
  type AttachByAliasParams = Parameters<UsePanelReviewersResult['attachReviewerByAlias']>[0];
  type DetachParams = Parameters<UsePanelReviewersResult['detachReviewer']>[0];
  type GetParams = Parameters<UsePanelReviewersResult['getReviewerByAlias']>[0];

  const getReviewerQueryParams = useCallback(
    (params: GetParams) => ({
      meta: { errorMessage: `Error fetching calibrated panel reviewer: ${params}` },
      queryKey: QueryKeys.calibratedPanelReviewer.alias(params),
      queryFn: async () => {
        if (!params) return null;
        const data = await getItem<
          GetCalibratedPanelReviewerRecordByAliasQueryVariables,
          GetCalibratedPanelReviewerRecordByAliasQuery
        >({
          query: getCalibratedPanelReviewerRecordByAlias,
          input: { alias: params },
        });
        const models = getConcreteModels<ReviewerItemModel>(
          data?.getCalibratedPanelReviewerRecordByAlias?.items,
          isValidReviewerItem
        );
        return models?.length ? getReviewerItemFromModel(models[0]) : null;
      },
    }),
    [getItem]
  );

  const attachReviewerByIdMutation = useMutation({
    mutationFn: async (input: AttachByIdParams) =>
      createItem<CreatePanelReviewSessionToReviewerMappingMutation>(createPanelReviewSessionToReviewerMapping, input),
  });

  const attachReviewerByAliasMutation = useMutation({
    mutationFn: async (params: AttachByAliasParams) => {
      if (!params || !params?.alias || !params?.panelReviewRecordID) return null;
      const reviewer = await queryClient.fetchQuery({ ...getReviewerQueryParams(params.alias) });
      if (!reviewer) return null;

      const input: CreatePanelReviewSessionToReviewerMappingInput = {
        panelReviewRecordID: params.panelReviewRecordID,
        calibratedPanelReviewerRecordID: reviewer.id,
      };

      return createItem<CreatePanelReviewSessionToReviewerMappingMutation>(
        createPanelReviewSessionToReviewerMapping,
        input
      );
    },
  });

  const detachReviewerMutation = useMutation({
    mutationFn: async (params: DetachParams) => {
      let version = params._version;
      if (!version) {
        const reviewerMapping = await getItem<
          GetPanelReviewSessionToReviewerMappingQueryVariables,
          GetPanelReviewSessionToReviewerMappingQuery
        >({
          query: getPanelReviewSessionToReviewerMapping,
          input: { id: params.id },
        });
        version = reviewerMapping?.getPanelReviewSessionToReviewerMapping?._version ?? 1;
      }
      const input: DeletePanelReviewSessionToReviewerMappingInput = { id: params.id, _version: version };
      return deleteItem<DeletePanelReviewSessionToReviewerMappingMutation>(
        deletePanelReviewSessionToReviewerMapping,
        input
      );
    },
  });

  const attachReviewerById = useCallback(
    async (input: AttachByIdParams) => attachReviewerByIdMutation.mutateAsync(input),
    [attachReviewerByIdMutation]
  );

  const attachReviewerByAlias = useCallback(
    async (input: AttachByAliasParams) => attachReviewerByAliasMutation.mutateAsync(input),
    [attachReviewerByAliasMutation]
  );

  const detachReviewer = useCallback(
    async (input: DetachParams) => detachReviewerMutation.mutateAsync(input),
    [detachReviewerMutation]
  );

  const getReviewerByAlias = useCallback(
    async (input: GetParams) => queryClient.fetchQuery({ ...getReviewerQueryParams(input) }),
    [queryClient, getReviewerQueryParams]
  );

  return { attachReviewerById, attachReviewerByAlias, detachReviewer, getReviewerByAlias };
}

export function usePanelReviewSesionVoteRecords(): UsePanelReviewVotesResult {
  const { createItem, updateItem } = useApiMutation();
  const { addNotification } = useNotifications();
  const queryClient = useQueryClient();
  const { getItems, getItem } = useApiQuery();
  const { getPanelReviewSession } = usePanelReviewSession(null);

  type ListVotesParams = Parameters<UsePanelReviewVotesResult['listVotesBySessionId']>[0];
  type CreateVoteParams = Parameters<UsePanelReviewVotesResult['onCreate']>[0];
  type UpdateVoteParams = Parameters<UsePanelReviewVotesResult['onUpdate']>[0];

  const invalidateQueries = useCallback(async () => {
    const invalidations = [queryClient.invalidateQueries({ queryKey: QueryKeys.panelReviewSessionVotes.all })];
    await Promise.allSettled(invalidations);
  }, [queryClient]);

  const listVotesParams = useCallback(
    (params: ListVotesParams) => ({
      meta: { errorMessage: `Error fetching panel review session votes for session: ${params}` },
      queryKey: QueryKeys.panelReviewSessionVotes.panelReviewSession(params.panelReview),
      queryFn: async () => {
        if (!params) return null;

        const panelReviewSession = await getPanelReviewSession(params.panelReview);
        if (!panelReviewSession) return null;

        const { panelReviewers } = panelReviewSession;
        const validReviewer =
          panelReviewers.find((reviewer) => reviewer?.alias === params.alias) ||
          params.alias === panelReviewSession.areaLeadAlias;

        if (params.alias !== panelReviewSession?.ownerAlias && !validReviewer) return null;

        let panelReviewSessionVotes;
        let panelReviewSessionVoteModels;

        if (params.alias === panelReviewSession?.ownerAlias || params.state === PanelReviewState.COMPLETE) {
          panelReviewSessionVotes = await getItems<ListVotesbyPanelReviewQueryVariables, ListVotesbyPanelReviewQuery>({
            query: listVotesbyPanelReview,
            input: params,
          });
          panelReviewSessionVoteModels = getConcreteModels<SessionVoteItemModel>(
            panelReviewSessionVotes?.listVotesbyPanelReview?.items,
            isValidSessionVoteItem
          );
        } else if (params.state !== PanelReviewState.SCHEDULED && params.state !== PanelReviewState.CANCELLED) {
          const availableStates: PanelReviewState[] = [];
          const currentState = params.state;

          if (currentState === PanelReviewState.FINAL_OUTCOME) {
            availableStates.push(PanelReviewState.INITIAL_VOTE, PanelReviewState.FINAL_VOTE);
          } else if (currentState === PanelReviewState.FINAL_VOTE) {
            availableStates.push(PanelReviewState.INITIAL_VOTE);
          }

          panelReviewSessionVotes = await getItems<ListVotesbyPanelReviewQueryVariables, ListVotesbyPanelReviewQuery>({
            query: listVotesbyPanelReview,
            input: {
              ...params,
              reviewerAliasVoteStage: { eq: { reviewerAlias: params.alias, voteStage: params.state } },
            },
          });

          panelReviewSessionVoteModels = getConcreteModels<SessionVoteItemModel>(
            panelReviewSessionVotes?.listVotesbyPanelReview?.items,
            isValidSessionVoteItem
          );

          if (availableStates && availableStates.length > 0) {
            let remainingSessionVotes = await getItems<
              ListVotesbyPanelReviewStateQueryVariables,
              ListVotesbyPanelReviewStateQuery
            >({
              query: listVotesbyPanelReviewState,
              input: {
                ...params,
                voteStage: availableStates[0],
                panelReview: { eq: params.panelReview },
              },
            });

            let remainingSessionVotesModels = getConcreteModels<SessionVoteItemModel>(
              remainingSessionVotes?.listVotesbyPanelReviewState?.items,
              isValidSessionVoteItem
            );

            if (!panelReviewSessionVoteModels && remainingSessionVotesModels) {
              panelReviewSessionVoteModels = remainingSessionVotesModels;
            } else if (panelReviewSessionVoteModels && remainingSessionVotesModels) {
              panelReviewSessionVoteModels.push(...remainingSessionVotesModels);
            }

            if (availableStates.length === 2) {
              remainingSessionVotes = await getItems<
                ListVotesbyPanelReviewStateQueryVariables,
                ListVotesbyPanelReviewStateQuery
              >({
                query: listVotesbyPanelReviewState,
                input: {
                  ...params,
                  voteStage: availableStates[1],
                  panelReview: { eq: params.panelReview },
                },
              });

              remainingSessionVotesModels = getConcreteModels<SessionVoteItemModel>(
                remainingSessionVotes?.listVotesbyPanelReviewState?.items,
                isValidSessionVoteItem
              );

              if (!panelReviewSessionVoteModels && remainingSessionVotesModels) {
                panelReviewSessionVoteModels = remainingSessionVotesModels;
              } else if (panelReviewSessionVoteModels && remainingSessionVotesModels) {
                panelReviewSessionVoteModels.push(...remainingSessionVotesModels);
              }
            }
          }
        }

        return panelReviewSessionVoteModels?.map((item) => getSessionVoteItemFromModel(item)) ?? [];
      },
    }),
    [getItems, getPanelReviewSession]
  );

  const createMutation = useMutation({
    mutationFn: async (params: CreateVoteParams) => {
      const input: CreatePanelReviewVoteRecordInput = { ...params };

      if (!params || !params.panelReview || !params.reviewerAlias || params.id) return null;

      const existingVoteInput = {
        reviewerAlias: params.reviewerAlias,
      };

      // Check to see if a vote for this reviewer and this review session and state already exist
      const existingVotes = await getItems<ListVotesbyPanelReviewerQueryVariables, ListVotesbyPanelReviewerQuery>({
        query: listVotesbyPanelReviewer,
        input: {
          ...existingVoteInput,
          panelReviewVoteStage: { eq: { panelReview: params.panelReview, voteStage: params.voteStage } },
        },
      });

      // Vote for this session+reviewer+state combo already exists, do not create a dupe
      if (existingVotes?.listVotesbyPanelReviewer?.items?.length) {
        if (
          existingVotes?.listVotesbyPanelReviewer?.items.map((vote) => {
            return vote?.panelReview === params.panelReview;
          })
        )
          return null;
      }

      const data = await createItem<CreatePanelReviewVoteRecordMutation>(createPanelReviewVoteRecord, input);
      return data?.createPanelReviewVoteRecord
        ? getSessionVoteItemFromModel(data?.createPanelReviewVoteRecord as SessionVoteItemModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully created panel review session vote: ${getCompactId(result?.id)}`,
      });
      invalidateQueries();
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateVoteParams) => {
      if (!params || !params.id) return null;
      const existingVoteData = await getItem<GetPanelReviewVoteRecordQueryVariables, GetPanelReviewVoteRecordQuery>({
        query: getPanelReviewVoteRecord,
        input: { id: params.id },
      });

      if (!existingVoteData) return null;
      const existingVote = getSessionVoteItemFromModel(
        existingVoteData.getPanelReviewVoteRecord as SessionVoteItemModel
      );
      const input: UpdatePanelReviewVoteRecordInput = { ...params, _version: existingVote.version };
      const data = await updateItem<UpdatePanelReviewVoteRecordMutation>(updatePanelReviewVoteRecord, input);
      return data?.updatePanelReviewVoteRecord
        ? getSessionVoteItemFromModel(data?.updatePanelReviewVoteRecord as SessionVoteItemModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully updated panel review session vote: ${getCompactId(result?.id)}`,
      });
      invalidateQueries();
    },
  });

  const listVotesBySessionId = useCallback(
    async (input: ListVotesParams) => queryClient.fetchQuery({ ...listVotesParams(input) }),
    [queryClient, listVotesParams]
  );

  const onCreate = useCallback(
    async (params: CreateVoteParams) => createMutation.mutateAsync(params),
    [createMutation]
  );
  const onUpdate = useCallback(
    async (params: UpdateVoteParams) => updateMutation.mutateAsync(params),
    [updateMutation]
  );

  return { listVotesBySessionId, onCreate, onUpdate };
}

export function usePanelReviewSesionCommentRecords(): UsePanelReviewCommentsResult {
  const { createItem, updateItem, deleteItem } = useApiMutation();
  const { addNotification } = useNotifications();
  const queryClient = useQueryClient();
  const { getItems, getItem } = useApiQuery();
  const { getPanelReviewSession } = usePanelReviewSession(null);

  type ListCommentsParams = Parameters<UsePanelReviewCommentsResult['listCommentsBySessionId']>[0];
  type CreateCommentParams = Parameters<UsePanelReviewCommentsResult['onCreatePanelComment']>[0];
  type UpdateCommentParams = Parameters<UsePanelReviewCommentsResult['onUpdatePanelComment']>[0];
  type DeleteCommentParams = Parameters<UsePanelReviewCommentsResult['onDeletePanelComment']>[0];

  const invalidateQueries = useCallback(async () => {
    const invalidations = [queryClient.invalidateQueries({ queryKey: QueryKeys.panelReviewSessionComments.all })];
    await Promise.allSettled(invalidations);
  }, [queryClient]);

  const listCommentsParams = useCallback(
    (params: ListCommentsParams) => ({
      meta: { errorMessage: `Error fetching panel review session comments for session: ${params}` },
      queryKey: QueryKeys.panelReviewSessionComments.panelReviewSession(params.panelReviewId),
      queryFn: async () => {
        if (!params) return [];

        const panelReviewSession = await getPanelReviewSession(params.panelReviewId);
        if (!panelReviewSession) return [];

        const { panelReviewers } = panelReviewSession;
        const validReviewer =
          panelReviewers.find((reviewer) => reviewer?.alias === params.alias) ||
          params.alias === panelReviewSession.areaLeadAlias;

        if (params.alias !== panelReviewSession?.ownerAlias && !validReviewer) return [];

        const panelReviewSessionComments = await getItems<
          ListCommentsByPanelReviewQueryVariables,
          ListCommentsByPanelReviewQuery
        >({
          query: listCommentsByPanelReview,
          input: params,
        });

        const panelReviewSessionCommentModels = getConcreteModels<SessionCommentItemModel>(
          panelReviewSessionComments?.listCommentsByPanelReview?.items,
          isValidSessionCommentItem
        )
          ?.filter((comment) => {
            return comment?._deleted !== true;
          })
          ?.sort((a, b) => {
            return (a?.createdAt ?? '').localeCompare(b?.createdAt ?? '');
          });

        return panelReviewSessionCommentModels?.map((item) => getSessionCommentItemFromModel(item)) ?? [];
      },
    }),
    [getItems, getPanelReviewSession]
  );

  const createMutation = useMutation({
    mutationFn: async (params: CreateCommentParams) => {
      const input: CreatePanelReviewCommentInput = { ...params };

      if (!params || !params.panelReviewId || !params.alias || !params.content) return null;

      const data = await createItem<CreatePanelReviewCommentMutation>(createPanelReviewComment, input);
      return data?.createPanelReviewComment
        ? getSessionCommentItemFromModel(data?.createPanelReviewComment as SessionCommentItemModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully created panel review session comment: ${getCompactId(result?.id)}`,
      });
      invalidateQueries();
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateCommentParams) => {
      if (!params || !params.id) return null;
      const existingCommentData = await getItem<GetPanelReviewCommentQueryVariables, GetPanelReviewCommentQuery>({
        query: getPanelReviewComment,
        input: { id: params.id },
      });

      if (!existingCommentData) return null;
      const existingComment = getSessionCommentItemFromModel(
        existingCommentData.getPanelReviewComment as SessionCommentItemModel
      );
      const input: UpdatePanelReviewCommentInput = { ...params, _version: existingComment.version };
      const data = await updateItem<UpdatePanelReviewCommentMutation>(updatePanelReviewComment, input);
      return data?.updatePanelReviewComment
        ? getSessionCommentItemFromModel(data?.updatePanelReviewComment as SessionCommentItemModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully updated panel review session comment: ${getCompactId(result?.id)}`,
      });
      invalidateQueries();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (params: DeleteCommentParams) => {
      if (!params || !params.id) return null;
      const existingCommentData = await getItem<GetPanelReviewCommentQueryVariables, GetPanelReviewCommentQuery>({
        query: getPanelReviewComment,
        input: { id: params.id },
      });

      if (!existingCommentData) return null;
      const existingComment = getSessionCommentItemFromModel(
        existingCommentData.getPanelReviewComment as SessionCommentItemModel
      );
      const input: DeletePanelReviewCommentInput = { ...params, _version: existingComment.version };
      const data = await deleteItem<DeletePanelReviewCommentMutation>(deletePanelReviewComment, input);
      return data?.deletePanelReviewComment
        ? getSessionCommentItemFromModel(data?.deletePanelReviewComment as SessionCommentItemModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully deleted panel review session comment: ${getCompactId(result?.id)}`,
      });
      invalidateQueries();
    },
  });

  const listCommentsBySessionId = useCallback(
    async (input: ListCommentsParams) => queryClient.fetchQuery({ ...listCommentsParams(input) }),
    [queryClient, listCommentsParams]
  );

  const onCreatePanelComment = useCallback(
    async (params: CreateCommentParams) => createMutation.mutateAsync(params),
    [createMutation]
  );

  const onUpdatePanelComment = useCallback(
    async (params: UpdateCommentParams) => updateMutation.mutateAsync(params),
    [updateMutation]
  );

  const onDeletePanelComment = useCallback(
    async (params: DeleteCommentParams) => deleteMutation.mutateAsync(params),
    [deleteMutation]
  );

  return {
    listCommentsBySessionId,
    onCreatePanelComment,
    onUpdatePanelComment,
    onDeletePanelComment,
    isMutating: createMutation.isPending || updateMutation.isPending || deleteMutation.isPending,
  };
}
