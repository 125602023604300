import React, { useRef, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Cards from '@amzn/awsui-components-react/polaris/cards';
import { Pagination } from '@amzn/awsui-components-react';
import { NoItemsTableState, NoMatchTableState } from '@/components/common/table/table-states';
import { CommentCardDefinition, CommentCardHeader } from './common-components';
import CreateEditCommentModal from './CreateEditCommentModal';
import { getFilterCountTextLabel, getNameDefinition } from '@/common/labels';
import { RESOURCES } from '@/common/constants';
import DeleteCommentModal from './DeleteCommentModal';
import { PanelReviewSessionCommentItem } from '@/models';
import { PanelReviewState } from '@/api/API';

const DEFAULT_CARDS_PER_ROW = 1;
const DEFAULT_TEXT_FILTER_FIELDS = ['content', 'createdAt', 'updatedAt', 'authorAlias', 'authorName'];
const resource = RESOURCES.COMMENT;

interface CommentListParams {
  comments: Array<PanelReviewSessionCommentItem>;
  isCommentsLoading: boolean;
  panelReviewId: string;
  voteStage: PanelReviewState;
  commentRefresh: () => void;
}

const CommentList = ({
  comments,
  isCommentsLoading,
  panelReviewId,
  voteStage,
  commentRefresh,
}: CommentListParams): JSX.Element => {
  const [isCreateEditVisible, setIsCreateEditVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [createEditMode, setCreateEditMode] = useState<'create' | 'edit'>('create');
  const ref = useRef<HTMLElement>(null);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    comments,
    {
      filtering: {
        fields: DEFAULT_TEXT_FILTER_FIELDS,
        empty: <NoItemsTableState resourceName={getNameDefinition(resource)} showCreate={false} />,
        noMatch: <NoMatchTableState onClearFilter={() => actions.setFiltering('')} />,
      },
      selection: { trackBy: resource.idKey },
      pagination: { pageSize: 5 },
    }
  );

  return (
    <>
      <Cards
        {...collectionProps}
        cardsPerRow={[{ cards: DEFAULT_CARDS_PER_ROW }]}
        stickyHeader
        stickyHeaderVerticalOffset={69}
        variant="container"
        loading={isCommentsLoading}
        loadingText="Loading comments"
        items={items}
        selectionType="single"
        pagination={<Pagination {...paginationProps} disabled={isCommentsLoading} />}
        totalItemsCount={5}
        header={
          <CommentCardHeader
            item={collectionProps?.selectedItems?.[0]}
            setIsCreateEditVisible={setIsCreateEditVisible}
            setCreateEditMode={setCreateEditMode}
            setIsDeleteVisible={setIsDeleteVisible}
            countText={getFilterCountTextLabel(filteredItemsCount)}
            {...filterProps}
          />
        }
        cardDefinition={CommentCardDefinition()}
      />
      <CreateEditCommentModal
        isVisible={isCreateEditVisible}
        onSetVisibility={setIsCreateEditVisible}
        mode={createEditMode}
        comment={collectionProps.selectedItems?.[0]}
        panelReviewId={panelReviewId}
        voteStage={voteStage}
        ref={ref}
        commentRefresh={commentRefresh}
      />
      <DeleteCommentModal
        isVisible={isDeleteVisible}
        onSetVisibility={setIsDeleteVisible}
        comment={collectionProps.selectedItems?.[0]}
        commentRefresh={commentRefresh}
      />
    </>
  );
};

export default React.memo(CommentList);
