export enum Vote {
  Yes = 'Yes',
  No = 'No',
}

export interface UserVote {
  inclined: Vote | null;
}

export interface Reviewer {
  firstName: string;
  lastName: string;
  alias: string;
}

export interface Owner {
  firstName: string;
  lastName: string;
  alias: string;
}

export const MIN_REVIEWERS_VOTES = 1;

export const REVIEW_SESSION_REFRESH_SECONDS = 5;
