import { Table, SpaceBetween, Button, FormField, Form, Select, TextContent } from '@amzn/awsui-components-react';

import { Reviewer, UserVote, Vote } from '../config';
import { CalibratedReviewerItem, PanelReviewSessionVoteItem } from '@/models';
import { PanelReviewState } from '@/api/API';

interface VotingSectionProps {
  user: string;
  isOwner: boolean;
  sessionState: PanelReviewState;
  reviewers: Array<Reviewer>;
  skipReviewer: Reviewer;
  sessionVotes: Map<PanelReviewState, Map<string, PanelReviewSessionVoteItem>>;
  userVote: UserVote;
  onVote: (voteType: keyof UserVote, value: string) => void;
  onSave: () => void;
  onRefresh: () => void;
}

const VotingSection: React.FC<VotingSectionProps> = ({
  user,
  isOwner,
  sessionState,
  reviewers,
  skipReviewer,
  sessionVotes,
  userVote,
  onVote,
  onSave,
  onRefresh,
}) => {
  let isVotingEnabled = false;

  if (user) {
    if (
      sessionState === PanelReviewState.INITIAL_VOTE ||
      sessionState === PanelReviewState.FINAL_VOTE ||
      (user === skipReviewer.alias && sessionState === PanelReviewState.FINAL_OUTCOME)
    ) {
      isVotingEnabled = true;
    }
  }

  let voteStage = '';
  // eslint-disable-next-line default-case
  switch (sessionState) {
    case PanelReviewState.INITIAL_VOTE:
      voteStage = 'initial vote';
      break;
    case PanelReviewState.FINAL_VOTE:
      voteStage = 'final vote';
      break;
    case PanelReviewState.FINAL_OUTCOME:
      voteStage = 'final outcome';
      break;
  }

  const skipArray = [{ alias: skipReviewer.alias, firstName: skipReviewer.firstName, lastName: skipReviewer.lastName }];

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <SpaceBetween direction="vertical" size="l">
        <Table
          items={reviewers}
          header={<h2>Reviewers</h2>}
          columnDefinitions={[
            { header: 'Reviewer', cell: (item: Reviewer) => `${item.firstName} ${item.lastName} (@${item.alias})` },
            {
              header: 'Initial Vote',
              cell: (item: Reviewer) => sessionVotes.get(PanelReviewState.INITIAL_VOTE)?.get(item.alias)?.vote || '-',
            },
            {
              header: 'Final Vote',
              cell: (item: Reviewer) => sessionVotes.get(PanelReviewState.FINAL_VOTE)?.get(item.alias)?.vote || '-',
            },
          ]}
        />
        <Table
          items={skipArray}
          header={<h2>Skip Reviewer</h2>}
          columnDefinitions={[
            { header: 'Reviewer', cell: (item: Reviewer) => `${item.firstName} ${item.lastName} (@${item.alias})` },
            {
              header: 'Initial Vote',
              cell: (item: Reviewer) => sessionVotes.get(PanelReviewState.INITIAL_VOTE)?.get(item.alias)?.vote || '-',
            },
            {
              header: 'Final Vote',
              cell: (item: Reviewer) => sessionVotes.get(PanelReviewState.FINAL_VOTE)?.get(item.alias)?.vote || '-',
            },
            {
              header: 'Final Outcome',
              cell: (item: Reviewer) => sessionVotes.get(PanelReviewState.FINAL_OUTCOME)?.get(item.alias)?.vote || '-',
            },
          ]}
        />
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="normal"
                onClick={() => {
                  onRefresh();
                }}
              >
                Refresh votes
              </Button>
              {!isOwner && (
                <Button
                  variant="primary"
                  onClick={() => {
                    onSave();
                  }}
                >
                  Submit vote
                </Button>
              )}
            </SpaceBetween>
          }
        >
          <SpaceBetween direction="vertical" size="l">
            {!isOwner && (
              <FormField label={`Select your ${voteStage}`}>
                <Select
                  selectedOption={userVote.inclined ? { label: userVote.inclined } : null}
                  onChange={({ detail }) => onVote('inclined', detail.selectedOption.value as Vote)}
                  options={[
                    { label: Vote.Yes, value: Vote.Yes },
                    { label: Vote.No, value: Vote.No },
                  ]}
                  disabled={!isVotingEnabled}
                  placeholder="Select your vote"
                />
              </FormField>
            )}
            {!isOwner && !isVotingEnabled && (
              <TextContent>
                <p>
                  Reviewer voting is only enabled when the session is in the Initial Vote or Final Vote stage. Final
                  Outcome voting is enabled for skip reviewer only.
                </p>
              </TextContent>
            )}
          </SpaceBetween>
        </Form>
      </SpaceBetween>
    </form>
  );
};

export default VotingSection;
