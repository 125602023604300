/* eslint-disable import/prefer-default-export */
import { Nullable, OptionalString } from 'src/models';
import { CommentType, DocumentReviewSessionState, PanelReviewState, PeerReviewStatus, PeerReviewType } from './API';

export const QueryKeys = {
  amazon: {
    all: ['amazon'],
    alias: (userAlias: OptionalString) => ['amazon', userAlias],
  },
  candidatePath: {
    all: ['candidatePath'],
    alias: (alias: OptionalString) => ['candidatePath', 'alias', alias],
    manager: (manager: OptionalString) => ['candidatePath', 'manager', manager],
    id: (id: OptionalString) => ({
      all: ['candidatePath', id],
      workSummaries: ['candidatePath', id, 'workSummaries'],
    }),
  },
  peerReview: {
    all: ['peerReview'],
    id: (peerReviewId: OptionalString) => ['peerReview', peerReviewId],
    alias: (userAlias: OptionalString) => ({
      all: ['peerReview', userAlias],
      status: (prStatuses: Nullable<PeerReviewStatus[]>) => ['peerReview', userAlias, prStatuses?.toString()],
    }),
    workSummary: {
      all: ['peerReview', 'workSummary'],
      id: (workSummaryId: OptionalString) => ['peerReview', 'workSummary', workSummaryId],
    },
  },
  promoPath: {
    all: ['promoPath'],
    manager: (alias: OptionalString) => ['promoPath', alias],
    id: (promoPathId: OptionalString) => ({
      all: ['promoPath', promoPathId],
      auditTrail: ['promoPath', promoPathId, 'auditTrail'],
    }),
  },
  directReport: {
    all: ['directReport'],
    alias: (userAlias: OptionalString) => ['directReport', userAlias],
  },
  managementChain: {
    all: ['managementChain'],
    alias: (userAlias: OptionalString) => ['managementChain', userAlias],
  },
  documentReviewSession: {
    all: ['documentReviewSession'],
    id: (documentReviewSessionId: OptionalString) => ['documentReviewSession', documentReviewSessionId],
    alias: (userAlias: OptionalString) => ({
      all: ['documentReviewSession', userAlias],
      status: (drStates: Nullable<DocumentReviewSessionState[]>) => [
        'documentReviewSession',
        userAlias,
        drStates?.toString(),
      ],
    }),
    candidateAlias: (candidateAlias: OptionalString) => ({
      all: ['documentReviewSession', candidateAlias],
      status: (drStates: Nullable<DocumentReviewSessionState[]>) => [
        'documentReviewSession',
        candidateAlias,
        drStates?.toString(),
      ],
    }),
  },
  documentReviewSessionVotes: {
    all: ['documentReviewSession'],
    documentReviewSession: (documentReviewSessionId: OptionalString) => [
      'documentReviewSessionVotes',
      documentReviewSessionId,
    ],
  },
  calibratedDocumentReviewer: {
    all: ['calibratedDocumentReviewer'],
    alias: (alias: OptionalString) => ['calibratedDocumentReviewer', alias],
  },
  panelReviewSession: {
    all: ['panelReviewSession'],
    id: (panelReviewSessionId: OptionalString) => ['panelReviewSession', panelReviewSessionId],
    alias: (userAlias: OptionalString) => ({
      all: ['panelReviewSession', userAlias],
      status: (prStates: Nullable<PanelReviewState[]>) => ['panelReviewSession', userAlias, prStates?.toString()],
    }),
  },
  panelReviewSessionVotes: {
    all: ['panelReviewSession'],
    panelReviewSession: (panelReviewSessionId: OptionalString) => ['panelReviewSessionVotes', panelReviewSessionId],
  },
  panelReviewSessionComments: {
    all: ['panelReviewSession'],
    panelReviewSession: (panelReviewSessionId: OptionalString) => ['panelReviewSessionComments', panelReviewSessionId],
  },
  calibratedPanelReviewer: {
    all: ['calibratedPanelReviewer'],
    alias: (alias: OptionalString) => ['calibratedPanelReviewer', alias],
  },
  category: {
    all: ['category'],
    role: (roleName: OptionalString) => ['category', roleName],
    id: (categoryId: OptionalString) => ['category', categoryId],
  },
  baselineRoleDimension: {
    all: ['baselineRoleDimension'],
    id: (baselineRoleDimensionId: OptionalString) => ['baselineRoleDimension', baselineRoleDimensionId],
    jobLevel: (baselineRoleDimensionJobLevel: number) => [
      'baselineRoleDimensionJobLevel',
      baselineRoleDimensionJobLevel,
    ],
  },
  fileRecord: {
    all: ['fileRecord'],
    alias: (alias: OptionalString) => ['fileRecord', alias],
    id: (fileId: OptionalString) => ['fileRecord', fileId],
    info: (fileId: OptionalString) => ['fileRecord', fileId],
  },
  profile: {
    all: ['profile'],
    alias: (profileAlias: OptionalString) => ['profile', profileAlias],
    authenticated: (profileAlias: OptionalString) => ['profile', 'authenticated', profileAlias],
  },
  comment: {
    all: ['comment'],
    id: (commentId: OptionalString) => ['comment', commentId],
  },
  workSummary: {
    all: ['workSummary'],
    alias: (alias: OptionalString) => ['workSummary', alias],
    aliasList: (aliasList: Array<OptionalString>) => ['workSummary', aliasList],
    id: (id: OptionalString) => ({
      all: ['workSummary', id],
      auditTrail: ['workSummary', id, 'auditTrail'],
      comments: {
        all: ['workSummary', id, 'comments'],
        type: (commentType: Nullable<CommentType>) => ['workSummary', id, 'comments', commentType],
      },
      peerReviews: {
        all: ['workSummary', id, 'peerReviews'],
        type: (reviewType: Nullable<PeerReviewType>) => ['workSummary', id, 'peerReviews', reviewType],
      },
    }),
  },
  cognito: ['cognito'],
};
