import { SpaceBetween, TextContent, Box, StatusIndicator, Button, Form, Select } from '@amzn/awsui-components-react';
import { PanelReviewSessionResource } from '@/models';
import { RESOURCES } from '@/common/constants';
import { RoutableLink } from '@/components/common/links';
import { PanelReviewState } from '@/api/API';
import { Owner } from '../config';

interface SessionInfoProps {
  panelReviewSession: PanelReviewSessionResource;
  isOwner: boolean;
  owner: Owner;
  initialSessionState: PanelReviewState;
  sessionState: PanelReviewState;
  onStateChange: (newState: PanelReviewState) => void;
  onSaveStateChange: (newState: PanelReviewState) => void;
}

const SessionInfo: React.FC<SessionInfoProps> = ({
  panelReviewSession,
  isOwner,
  owner,
  initialSessionState,
  sessionState,
  onStateChange,
  onSaveStateChange,
}) => (
  <SpaceBetween size="m">
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session ID:
      </Box>{' '}
      <RoutableLink
        href={`${RESOURCES.PANEL_REVIEW_SESSION.hrefToReview}/${panelReviewSession.id?.toString()}`}
        text={panelReviewSession.id?.toString()}
      />
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Workdocs:
      </Box>{' '}
      <a href={panelReviewSession.workdocsLink} target="_blank" rel="noopener noreferrer">
        Link
      </a>
    </TextContent>
    {panelReviewSession.chimeLink && (
      <TextContent>
        <Box fontWeight="bold" variant="span">
          Chime Link:
        </Box>{' '}
        <a href={panelReviewSession.chimeLink} target="_blank" rel="noopener noreferrer">
          {panelReviewSession.chimeLink}
        </a>
      </TextContent>
    )}
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session Date:
      </Box>{' '}
      {panelReviewSession.sessionStart?.toString()}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session Owner:
      </Box>{' '}
      {`${owner.firstName} ${owner.lastName} (@${panelReviewSession.ownerAlias})`}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session State:
      </Box>{' '}
      <StatusIndicator type="pending">{panelReviewSession.sessionState}</StatusIndicator>
    </TextContent>
    {isOwner ? (
      <form onSubmit={(e) => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="primary"
                disabled={sessionState === initialSessionState}
                onClick={() => onSaveStateChange(sessionState)}
              >
                Save
              </Button>
            </SpaceBetween>
          }
        >
          <TextContent>
            <h4>Modify session state</h4>
          </TextContent>
          <Select
            selectedOption={{ label: sessionState }}
            onChange={({ detail }) => onStateChange(detail.selectedOption.value as PanelReviewState)}
            options={[
              {
                label: PanelReviewState.SCHEDULED,
                value: PanelReviewState.SCHEDULED,
                disabled: sessionState === PanelReviewState.SCHEDULED || sessionState !== PanelReviewState.INITIAL_VOTE,
              },
              {
                label: PanelReviewState.INITIAL_VOTE,
                value: PanelReviewState.INITIAL_VOTE,
                disabled:
                  sessionState === PanelReviewState.INITIAL_VOTE ||
                  (sessionState !== PanelReviewState.SCHEDULED && sessionState !== PanelReviewState.FINAL_VOTE),
              },
              {
                label: PanelReviewState.FINAL_VOTE,
                value: PanelReviewState.FINAL_VOTE,
                disabled:
                  sessionState === PanelReviewState.FINAL_VOTE ||
                  (sessionState !== PanelReviewState.INITIAL_VOTE && sessionState !== PanelReviewState.FINAL_OUTCOME),
              },
              {
                label: PanelReviewState.FINAL_OUTCOME,
                value: PanelReviewState.FINAL_OUTCOME,
                disabled:
                  sessionState === PanelReviewState.FINAL_OUTCOME ||
                  (sessionState !== PanelReviewState.FINAL_VOTE && sessionState !== PanelReviewState.COMPLETE),
              },
              {
                label: PanelReviewState.COMPLETE,
                value: PanelReviewState.COMPLETE,
                disabled: sessionState === PanelReviewState.COMPLETE || sessionState !== PanelReviewState.FINAL_OUTCOME,
              },
            ]}
          />
        </Form>
      </form>
    ) : null}
  </SpaceBetween>
);

export default SessionInfo;
