import React from 'react';
import { createUseStyles } from 'react-jss';
import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import ProgressBar from '@amzn/awsui-components-react/polaris/progress-bar';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import KeyValuePair from '../components/common/KeyValuePair';
import WorkSummaryTable from '../components/WorkSummary/WorkSummaryTable';
import { CrossTableContextProvider } from '@/contexts/CrossTableContext';
import { useAppContext } from '@/contexts/AppContext';
import { CandidatePathResource, Nullable, PromoUserProfile } from '@/models';
import { useCandidatePath } from '@/api/candidate-promo-path';
import PromoSpinner from '../components/common/PromoSpinner';

interface PathAssignmentParams {
  path?: Nullable<CandidatePathResource>;
}

const PathStatus = React.memo<PathAssignmentParams>(
  ({ path }): JSX.Element => (
    <StatusIndicator type={path ? `${path.progressPct ? 'pending' : 'in-progress'}` : 'stopped'}>
      {path ? path.name : 'N/A'}
    </StatusIndicator>
  )
);


interface CandidateOverviewParams {
  candidate: Nullable<PromoUserProfile>;
}

const CandidateOverview = ({ candidate }: CandidateOverviewParams) => {
  const { candidatePath, isCandidatePathLoading } = useCandidatePath({ alias: candidate?.alias });

  return (
    <Container variant="stacked">
      {isCandidatePathLoading && !candidatePath ? (
        <PromoSpinner />
      ) : (
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween size="l">
            <KeyValuePair label="Role">{candidate?.jobTitle}</KeyValuePair>
            <ColumnLayout columns={2} variant="default">
              <KeyValuePair label="Current level">{`L${candidate?.jobLevel}`}</KeyValuePair>
              <KeyValuePair label="Target level">{candidatePath ? `L${candidatePath.targetLevel}` : '-'}</KeyValuePair>
            </ColumnLayout>
          </SpaceBetween>
        </ColumnLayout>
      )}
    </Container>
  );
};

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">My dashboard</Header>;
};

const CandidateDashboard = () => {
  const { currentUser, spoofUser } = useAppContext();

  return (
    <CrossTableContextProvider>
      <ContentLayout header={<PageHeader />}>
        <CandidateOverview candidate={spoofUser ?? currentUser} />
        <WorkSummaryTable candidate={spoofUser ?? currentUser} />
      </ContentLayout>
    </CrossTableContextProvider>
  );
};

export default CandidateDashboard;
